<template>
<v-navigation-drawer app v-if="logado" :permanent="changeNav" clipped left>
    <v-col cols="12" sm="12">
        <v-row align="center"  justify="center" class="pb-2">
          <img width="auto" height="200" src="../../assets/logo.png" alt="User">
        </v-row>
        <v-row>
           <v-divider></v-divider>
        </v-row>
        <v-row>
      <v-container class="pa-3">
            <router-link v-for="(item, i) in itensMenu" :key="i" class="link" :to="{ name: item.name }">
              <v-btn text small width="100%" class="mt-1 justify-start hover-btn">
                 <v-icon v-text="item.icon"></v-icon>
                 &nbsp;
                  {{ item.text }}
              </v-btn>
            </router-link>
      </v-container>
      </v-row>
    </v-col>
</v-navigation-drawer>
</template>

<script>
export default {
  props: ['logado'],
  computed: {
    getPermissions () {
      return this.$store.state.permissions.filter(P => {
        return P.permsId === 'duEJNQ0_QBCrtsAvCffkLQ'
      })
    },
    itensMenu () {
      if (this.$store.state.profile.rules.isSales) {
        return this.$store.state.MenuSALES
      } else if (this.$store.state.profile.rules.isMaster) {
        return this.$store.state.MenuMASTER
      } else if (this.$store.state.profile.rules.isAdmin) {
        return this.$store.state.MenuADM
      } else {
        if (!this.getPermissions.length > 0) {
          return this.$store.state.MenuUSER.filter(M => {
            return M.text !== 'Chamados'
          })
        } else {
          return this.$store.state.MenuUSER
        }
      }

      /* if (this.$store.state.profile.rules.isAdmin) {
        return this.$store.state.MenuADM
      } else {
        if (this.getPermissions.length > 0) {
          return this.$store.state.MenuUSER
        }
        return this.$store.state.MenuUSEROFF
      } */
    },
    changeNav () {
      return this.$store.state.showNav
    }
  }
}
</script>

<style>
.photo{
  border: solid 1px black !important;
}
.hover-btn:hover {
  color: rgb(52, 52, 245)
}
</style>
