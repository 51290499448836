import firebase from 'firebase/app'
import 'firebase/firestore'

var firebaseConfig = {
  apiKey: 'AIzaSyCEUuQ1XoTzvRUq1n-aJrc3mjHqAtfoXkI',
  authDomain: 'app-csbim.firebaseapp.com',
  databaseURL: 'https://app-csbim-default-rtdb.firebaseio.com',
  projectId: 'app-csbim',
  storageBucket: 'app-csbim.appspot.com',
  messagingSenderId: '508628511799',
  appId: '1:508628511799:web:d22ce2354c771fc188e3b2'
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { timestamp }
export default firebaseApp.firestore()
