<template>
    <v-app-bar app color="white" v-if="logado" class="pa-0">
      <v-container class="fill-height flex-nowrap" style="max-width: 100%">
        <v-spacer></v-spacer>
        <v-speed-dial class="mt-1 mr-6" :direction="direction" :open-on-hover="hover" :transition="transition" v-if="!fromApp">
          <template v-slot:activator>
            <v-hover v-slot="{ hover }">
              <v-icon v-if="hover" @click="modalDel = true">mdi-bell-cancel-outline</v-icon>
              <v-icon v-else :color="colorNot">mdi-bell-badge</v-icon>
            </v-hover>
          </template>
          <v-responsive v-if="getNotifications.length > 0" style="background-color: azure;" class="overflow-y-auto mt-4 pa-1 pb-0" max-height="40vh" min-width="350px">
            <v-alert v-for="(item, i) in getNotifications" :key="i" :type="getType(item)" @click="notf = item, modal = true" class="mb-1 d-flex alert">
                <span v-if="item.docId === 'K20lSiL4s3qhVsSQYuzcqA'">Novo Chamado</span>
                <span v-if="item.docId === 'SXR8Tq5Lw9pfbuTXO6nUfg'">Chamado Finalizado</span>
                <span v-if="item.docId === 'uL5IYkqkrY3AFybSLtkHkw'">Novo Comentário</span>
              <span style="font-size: 10px;" class="alerts"><b>{{ new Date(item.criadoEm).toLocaleString('pt-BR', {year: 'numeric', month: 'numeric', day: 'numeric', hour: "2-digit", minute: "2-digit", hour12: false }) }}</b></span>
            </v-alert>
          </v-responsive>
        </v-speed-dial>
        <v-speed-dial class="mt-1" :direction="direction" :open-on-hover="hover" :transition="transition">
          <template v-slot:activator>
            <div class="d-flex">
              <v-avatar size="40"><img :src="profilePhotoURL"></v-avatar>
              <div style="display: flex; flex-direction: column; ">
                <small class="mx-2">{{ 'Olá, ' + profileName + '!' }}</small>
                <small class="mx-2">{{ profileEmail }}</small>
              </div>
            </div>
          </template>
          <div class="nav-bar">
            <router-link class="link" :to="{ name: 'MinhaConta' }" v-if="!fromApp">
              <v-btn text width="100%" class="my-1 mx-0 px-2 justify-start hover-btn-blue">
                <v-icon>mdi-account-edit-outline</v-icon>
                &nbsp;
                Minha Conta
              </v-btn>
            </router-link>
              <v-btn text width="100%" class="my-1 mx-0 px-2 justify-start hover-btn-red" @click="logout()">
                <v-icon>mdi-exit-to-app</v-icon>
                &nbsp;
                Sair
              </v-btn>
          </div>
        </v-speed-dial>
      </v-container>
    <v-dialog v-model="modal" max-width="300px">
      <v-card>
          <v-card-actions>
              <v-btn color="primary darken-1" text @click="openAlert()">
                  Abrir Notificação
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="delAlert()">
                  Excluir
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalDel" max-width="170px">
      <v-card>
          <v-card-actions>
              <v-btn color="red darken-1" text @click="delAlerts()">
                  Excluir Todas
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    </v-app-bar>
</template>

<script>
export default {
  props: ['logado'],
  data () {
    return {
      modalDel: false,
      modal: false,
      notf: null,
      direction: 'bottom',
      hover: true,
      transition: 'slide-y-reverse-transition'
    }
  },
  computed: {
    profilePhotoURL () {
      return this.$store.state.profile.photoURL
    },
    profileName () {
      return this.$store.state.profile.nome
    },
    profileEmail () {
      return this.$store.state.profile.email
    },
    fromApp () {
      return this.$store.state.fromApp
    },
    getNotifications () {
      return this.$store.state.notifications.slice().sort(this.compare)
    },
    colorNot () {
      const Nots = this.getNotifications.filter(N => {
        return N.status === 0
      })
      if (Nots.length > 0) {
        return 'red'
      } else {
        return ''
      }
    }
  },
  methods: {
    async logout () {
      await this.$store.dispatch('logout')
    },
    async openAlert () {
      await this.$store.dispatch('updateNotification', this.notf).then(async () => {
        if (this.$route.name === 'VisualizarChamado') {
          this.notf = null
          this.modal = false
        }
      })
    },
    async delAlert () {
      await this.$store.dispatch('delNotification', this.notf).then(() => {
        this.notf = null
        this.modal = false
      })
    },
    async delAlerts () {
      if (this.getNotifications.length > 0) {
        await this.$store.dispatch('delNotifications').then(() => {
          this.modalDel = false
          this.$store.dispatch('loading', false)
        })
      }
    },
    getType (item) {
      if (item.docId === 'SXR8Tq5Lw9pfbuTXO6nUfg') {
        return 'success'
      } else {
        if (item.status === 0) {
          return 'warning'
        } else {
          return 'info'
        }
      }
    },
    compare (notf1, notf2) {
      const bandA = notf2.criadoEm
      const bandB = notf1.criadoEm

      let comparison = 0
      if (bandA > bandB) {
        comparison = 1
      } else if (bandA < bandB) {
        comparison = -1
      }
      return comparison
    }
  }
}
</script>

<style>
.v-speed-dial__list {
  padding: 0 !important;
}
.nav-bar {
  width: 100%;
  background: white;
  padding: 4px;
}
.hover-btn-red:hover {
  color: red
}
.hover-btn-blue:hover {
  color: rgb(52, 52, 245)
}
.alerts {
  top: 2px;
  position: absolute;
  right: 10px;
}
.alert:hover {
  color: red !important;
  cursor: pointer;
}
.iconDelete {
  top: 15px !important;
}
.iconDelete:hover {
  color: red !important;
}
</style>
