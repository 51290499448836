<template>
  <v-footer app color="white" v-if="logado" padless>
    <v-col
      class="text-right"
      cols="12"
    >
       <v-divider></v-divider>
    <small v-if="fromApp"><a class="link">CSBIM</a> &copy; {{ new Date().getFullYear() }} - Todos os direitos reservados.</small>
    <small v-else><a class="link" href="https://www.csbim.eng.br">CSBIM</a> &copy; {{ new Date().getFullYear() }} - Todos os direitos reservados.</small>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  props: ['logado'],
  computed: {
    fromApp () {
      return this.$store.state.fromApp
    }
  }
}
</script>

<style>

</style>
