import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vue2Editor from 'vue2-editor'
import VueMgl, { mgl } from 'vue-mgl'
import vueVimeoPlayer from 'vue-vimeo-player'
import { VueMaskDirective } from 'v-mask'
import Vue2Filters from 'vue2-filters'
import VueCompositionAPI from '@vue/composition-api'
import cors from 'cors'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'mapbox-gl/dist/mapbox-gl.css'
mgl.accessToken = 'pk.eyJ1IjoidmljdG9yZmFndW5kZXMiLCJhIjoiY2t6b2p0Ymh1MmQ3ZzJ2czh5bTR1YmhtayJ9.Ri-sSaZvt5MLuRhTIyHTMg'
/* const corsMiddleware = require('cors')
const express = require('express')
const app = express()
app.options('*', corsMiddleware)
app.use(corsMiddleware) */
Vue.use(cors)
Vue.directive('mask', VueMaskDirective)
Vue.use(Vue2Editor)
Vue.config.productionTip = false
Vue.use(VueMgl)
Vue.use(vueVimeoPlayer)
Vue.use(Vue2Filters)
Vue.use(VueCompositionAPI)
new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
