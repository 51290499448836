<template>
 <v-main fill-height fluid>
    <BaseSpinner />
    <router-view />
</v-main>
</template>
<script>
import BaseSpinner from './BaseSpinner.vue'
export default {
  components: {
    BaseSpinner
  }
}
</script>

<style>
</style>
