<template>
<v-app class="app-font">
    <NavAppBar :logado='logado' />
    <NavSideBar :logado='logado' v-if="!loading"/>  <!-- v-if="!loading" -->
    <Content />
    <Footer :logado='logado' />
</v-app>
</template>

<script>
import NavAppBar from './components/global/NavAppBar.vue'
import Footer from './components/global/Footer.vue'
import Content from './components/global/Content.vue'
import NavSideBar from './components/global/NavSideBar.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, PointElement, LineElement, RadialLinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, RadialLinearScale)

export default {
  name: 'App',
  data: () => ({}),
  components: {
    NavAppBar,
    Footer,
    Content,
    NavSideBar
  },
  async created () {
    try {
      if (window.navigator.userAgent.split(';')[0] === 'cTvi3v2HgxOG8IEildvxxw') {
        this.$store.state.fromApp = true
        var arrayValues = window.navigator.userAgent.split(';')
        this.$store.state.infoLicenseApp = {
          TypeAccess: arrayValues[0],
          Plugin: arrayValues[1],
          MachineCode: arrayValues[2],
          MachineName: arrayValues[3],
          PM: arrayValues[4],
          HD: arrayValues[5],
          City: arrayValues[6],
          Location: arrayValues[7]
        }
      }
    } catch {
    }
    if (this.fromApp === true) {
      if (!this.logado) {
        firebase.auth().onAuthStateChanged(async user => {
          if (user) {
            await this.$store.dispatch('getCurrentUser', user).then(async () => {
              await this.$store.dispatch('getLicensesApp').then(async () => {
                await this.$router.push({
                  name: 'LicencasApp'
                }).catch(() => {})
              })
            })
          } else {
            if (this.$route.name !== 'Login') {
              this.$router.push({
                name: 'Login'
              }).catch(() => {})
            }
          }
        })
      } else {
        await this.$store.dispatch('getLicensesApp').then(async () => {
          await this.$router.push({
            name: 'LicencasApp'
          }).catch(() => {})
        })
      }
    } else {
      if (!this.logado) {
        firebase.auth().onAuthStateChanged(async user => {
          if (user) {
            await this.$store.dispatch('getCurrentUser', user).then(async () => {
              if (await this.$store.state.profile.rules.isMaster) {
                await this.retornarHomeMaster().then(async () => {
                  await this.$router.push({
                    name: 'MasterHome'
                  }).catch(() => {})
                })
              } else if (await this.$store.state.profile.rules.isSales) {
                await this.retornarHomeSales().then(async () => {
                  await this.$router.push({
                    name: 'Usuarios'
                  }).catch(() => {})
                })
              } else if (await this.$store.state.profile.rules.isAdmin) {
                await this.retornarHomeAdmin().then(async () => {
                  await this.$router.push({
                    name: 'AdminHome'
                  }).catch(() => {})
                })
              } else {
                await this.retornarHomeUser().then(async () => {
                  await this.$router.push({
                    name: 'UserHome'
                  }).catch(() => {})
                })
              }
            })
          } else {
            if (this.$route.name !== 'Login') {
              this.$router.push({
                name: 'Login'
              }).catch(() => {})
            }
          }
        })
      } else {
        if (await this.$store.state.profile.rules.isMaster) {
          await this.retornarHomeMaster().then(async () => {
            await this.$router.push({
              name: 'MasterHome'
            }).catch(() => {})
          })
        } else if (await this.$store.state.profile.rules.isSales) {
          await this.retornarHomeSales().then(async () => {
            await this.$router.push({
              name: 'Usuarios'
            }).catch(() => {})
          })
        } else if (await this.$store.state.profile.rules.isAdmin) {
          await this.retornarHomeAdmin().then(async () => {
            await this.$router.push({
              name: 'AdminHome'
            }).catch(() => {})
          })
        } else {
          await this.retornarHomeUser().then(async () => {
            await this.$router.push({
              name: 'UserHome'
            }).catch(() => {})
          })
        }
      }
    }
  },
  computed: {
    logado () {
      return this.$store.state.user
    },
    isAdmin () {
      return this.$store.state.profile.rules.isAdmin
    },
    isMaster () {
      return this.$store.state.profile.rules.isMaster
    },
    isSales () {
      return this.$store.state.profile.rules.isMaster
    },
    loading () {
      return this.$store.state.loading
    },
    fromApp () {
      return this.$store.state.fromApp
    }
  },
  methods: {
    async retornarHomeAdmin () {
      if (!this.$store.state.usersMembers.length > 0) {
        await this.$store.dispatch('getAllUsers')
      }
      if (!this.$store.state.adminMembers.length > 0) {
        await this.$store.dispatch('getAllAdmins')
      }
      if (!this.$store.state.permissions.length > 0) {
        await this.$store.dispatch('getAllPermissions')
      }
      if (!this.$store.state.notifications.length > 0) {
        await this.$store.dispatch('getAllNotifications')
      }
      if (!this.$store.state.tickets.length > 0) {
        await this.$store.dispatch('getAllTickets')
      }
    },
    async retornarHomeUser () {
      if (!this.$store.state.permissions.length > 0) {
        await this.$store.dispatch('getAllPermissions')
      }
      if (!this.$store.state.advertisings.length > 0) {
        await this.$store.dispatch('getAllAdvertisings')
      }
      if (!this.$store.state.notifications.length > 0) {
        await this.$store.dispatch('getAllNotifications')
      }
      if (!this.$store.state.adminMembers.length > 0) {
        await this.$store.dispatch('getAllAdmins')
      }
      if (!this.$store.state.tickets.length > 0) {
        await this.$store.dispatch('getAllTickets')
      }
    },
    async retornarHomeMaster () {
      if (!this.$store.state.usersMembers.length > 0) {
        await this.$store.dispatch('getAllUsers')
      }
      if (!this.$store.state.adminMembers.length > 0) {
        await this.$store.dispatch('getAllAdmins')
      }
      if (!this.$store.state.notifications.length > 0) {
        await this.$store.dispatch('getAllNotifications')
      }
      await this.$store.dispatch('getMasterHome')
    },
    async retornarHomeSales () {
      if (!this.$store.state.usersMembers.length > 0) {
        await this.$store.dispatch('getAllUsers')
      }
      if (!this.$store.state.permissions.length > 0) {
        await this.$store.dispatch('getAllPermissions')
      }
      if (!this.$store.state.courses.length > 0) {
        await this.$store.dispatch('getAllCourses')
      }
      if (!this.$store.state.notifications.length > 0) {
        await this.$store.dispatch('getAllNotifications')
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
.app-font {
  font-family: 'Nunito', sans-serif;
}
.link {
  text-decoration: none !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.position-absolute {
  position: absolute !important;
}
::-webkit-scrollbar {
    display: none !important;
}
div::-webkit-scrollbar {
    width: 12px;
    display: block !important;
}
div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 10px;
}

div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,1);
}
</style>
