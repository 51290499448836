import Vue from 'vue'
import VueRouter from 'vue-router'
// import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      title: 'Entrar',
      icon: '',
      requiresAuth: false
    }
  },
  {
    path: '/home',
    name: 'AdminHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminHome.vue'),
    meta: {
      title: 'Início',
      icon: '',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/masterHome/',
    name: 'MasterHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/MasterHome.vue'),
    meta: {
      title: 'Inicío',
      icon: '',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/userHome/',
    name: 'UserHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/UserHome.vue'),
    meta: {
      title: 'Inicío',
      icon: '',
      requiresAuth: true
    }
  },
  {
    path: '/chamados',
    name: 'Chamados',
    component: () => import(/* webpackChunkName: "about" */ '../views/Tickets.vue'),
    meta: {
      title: 'Chamados',
      icon: '',
      requiresAuth: true
    }
  },
  {
    path: '/minhaconta',
    name: 'MinhaConta',
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
    meta: {
      title: 'Minha Conta',
      icon: '',
      requiresAuth: true
    }
  },
  {
    path: '/cursos',
    name: 'Cursos',
    component: () => import(/* webpackChunkName: "about" */ '../views/Courses.vue'),
    meta: {
      title: 'Cursos',
      icon: '',
      requiresAuth: true
    }
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: () => import(/* webpackChunkName: "about" */ '../views/Users.vue'),
    meta: {
      title: 'Usuários',
      icon: '',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/novochamado',
    name: 'NovoChamado',
    component: () => import(/* webpackChunkName: "about" */ '../components/tickets/New.vue'),
    meta: {
      title: 'Novo Chamado',
      icon: '',
      requiresAuth: true
    }
  },
  {
    path: '/novocurso',
    name: 'NovoCurso',
    component: () => import(/* webpackChunkName: "about" */ '../components/courses/New.vue'),
    meta: {
      title: 'Novo Curso',
      icon: '',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/novousuario',
    name: 'NovoUsuario',
    component: () => import(/* webpackChunkName: "about" */ '../components/users/New.vue'),
    meta: {
      title: 'Novo Usuário',
      icon: '',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/visualizarusuario/:iduser',
    name: 'VisualizarUsuario',
    component: () => import(/* webpackChunkName: "about" */ '../components/users/View.vue'),
    meta: {
      title: 'Usuários',
      icon: '',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/visualizarcurso/:idcurso',
    name: 'VisualizarCurso',
    component: () => import(/* webpackChunkName: "about" */ '../components/courses/View.vue'),
    meta: {
      title: 'Curso',
      icon: '',
      requiresAuth: true
    }
  },
  {
    path: '/novasaulas/:idcurso',
    name: 'NovasAulas',
    component: () => import(/* webpackChunkName: "about" */ '../components/courses/classes/New.vue'),
    meta: {
      title: 'Novas Aulas',
      icon: '',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/editarcurso/:idcurso',
    name: 'EditarCurso',
    component: () => import(/* webpackChunkName: "about" */ '../components/courses/Edit.vue'),
    meta: {
      title: 'Editar Curso',
      icon: '',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/downloads/',
    name: 'Downloads',
    component: () => import(/* webpackChunkName: "about" */ '../views/Downloads.vue'),
    meta: {
      title: 'Downloads',
      icon: '',
      requiresAuth: true
    }
  },
  {
    path: '/propostas/',
    name: 'CRMs',
    component: () => import(/* webpackChunkName: "about" */ '../views/CRMs.vue'),
    meta: {
      title: 'CRMs',
      icon: '',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/mapa/',
    name: 'Mapa',
    component: () => import(/* webpackChunkName: "about" */ '../views/Map.vue'),
    meta: {
      title: 'Mapa',
      icon: '',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/licenses/',
    name: 'Licencas',
    component: () => import(/* webpackChunkName: "about" */ '../views/Licenses.vue'),
    meta: {
      title: 'Licenças',
      icon: '',
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/licensesApp/',
    name: 'LicencasApp',
    component: () => import(/* webpackChunkName: "about" */ '../views/LicensesApp.vue'),
    meta: {
      title: 'Licenças',
      icon: '',
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: '/novodownload/',
    name: 'NovoDownload',
    component: () => import(/* webpackChunkName: "about" */ '../components/downloads/New.vue'),
    meta: {
      title: 'Novo Download',
      icon: '',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/visualizarchamado/:idchamado',
    name: 'VisualizarChamado',
    component: () => import(/* webpackChunkName: "about" */ '../components/tickets/View.vue'),
    meta: {
      title: 'Chamado',
      icon: '',
      requiresAuth: true
    }
  },
  {
    path: '/roleta',
    name: 'Roleta',
    component: () => import(/* webpackChunkName: "about" */ '../components/raffles/View.vue'),
    meta: {
      title: 'Roleta Sorteio',
      icon: '',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/sorteio/',
    name: 'Sorteio',
    component: () => import(/* webpackChunkName: "about" */ '../views/Raffle.vue'),
    meta: {
      title: 'Sorteio',
      icon: '',
      requiresAuth: false,
      requiresAdmin: false
    }
  },
  {
    path: '/propagandas/',
    name: 'Propagandas',
    component: () => import(/* webpackChunkName: "about" */ '../views/Advertisings.vue'),
    meta: {
      title: 'Propagandas',
      icon: '',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/Sugestoes/',
    name: 'Suggestions',
    component: () => import(/* webpackChunkName: "about" */ '../views/Suggestions.vue'),
    meta: {
      title: 'Sugestões',
      icon: '',
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/visualizarMapa/:docId',
    name: 'VisualizarMapa',
    component: () => import(/* webpackChunkName: "about" */ '../components/maps/View.vue'),
    meta: {
      title: 'Mapa',
      icon: '',
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `CSBIM | ${to.meta.title}`
  next()
})

/* router.beforeEach(async (to, from, next) => {
  if (to.matched.some((res) => res.meta.requiresAuth)) {
    const user = firebase.auth().currentUser
    if (user) {
      const token = await user.getIdTokenResult()
      if (to.matched.some((res) => res.meta.requiresAdmin)) {
        if (token.claims.admin) {
          return next()
        }
        return next({ name: 'Home' })
      }
      return next()
    }
    return next()
  }
  return next()
}) */

export default router
