<template>
  <div v-if="loading">
    <div class="base-spinner" >
      <v-progress-circular indeterminate size="70" color="cyan"> <span v-if="porcLoading > 0">{{ porcLoading + '%' }} </span></v-progress-circular>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    porcLoading () {
      return Math.round(this.$store.state.porcLoading)
    },
    loading () {
      return this.$store.state.loading
    }
  },
  data () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.base-spinner {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: blue;
  z-index: 1000000;
}
</style>
